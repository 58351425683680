import React from 'react';
import { Router } from '@reach/router';
import HomeSection from '../B2B-Clients/ClientsSection';
import AnalyticsSection from '../Analytics/AnalyticsSection';
import VoucherSection from '../Vouchers/VouchersSection';
import BuisnessUsersSection from '../B2B-Customers-Users-b2C/B2B-Customers-Users-b2CSection';
import CoursesSection from '../CoursesSection/CoursesSection';
import SessionsSection from '../Sessions/Sessions';
import useServiceWorkerMessages from '../../../hooks/useServiceWorkerMessages';
import useSubscribePushManager from '../../../hooks/useSubscribePushManager';

const AppSection: React.FC = () => {
  useSubscribePushManager();
  useServiceWorkerMessages();
  return (
    <Router basepath="/app">
      <HomeSection path="/" />
      <VoucherSection path="/code" />
      <SessionsSection path="/sessions" />
      <BuisnessUsersSection path="/users" />
      <AnalyticsSection path="/analytics" />
      <CoursesSection path="/courses" />
    </Router>
  );
};

export default AppSection;
