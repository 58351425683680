import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { RouteComponentProps } from '@reach/router';
import Header from '../../02_molecules/Header/Header';
import Popup from 'devextreme-react/popup';

import Text from '../../01_atoms/Text/Text';
import DataGrid, {
  Editing,
  Paging,
  Toolbar,
  Button as GridButton,
  Lookup,
  Column,
  Sorting,
  Selection,
  SearchPanel,
  Export,
} from 'devextreme-react/data-grid';
import SelectBox from 'devextreme-react/select-box';
import API from '../../../services/API';
import { BusinessUser, User } from '@youga/youga-interfaces';
import useAuth from '../../../hooks/useAuth';
import Papa from 'papaparse';
import Button from 'devextreme-react/button';
import 'devextreme/dist/css/dx.light.css'; // Import the theme you're using

const BuisnessUsersSection: React.FC<RouteComponentProps> = () => {
  const { token } = useAuth();
  const [customers, setCustomers] = useState<User[]>([]);
  const [businessUsers, setBusinessUsers] = useState<BusinessUser[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>('Wszyscy');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [actionSubmitted, setActionSubmitted] = useState('');
  const [toDelete, setToDelete] = useState(false);
  const [toVerify, setToVerify] = useState(false);

  const handleManageClick = (rowData: any) => {
    setSelectedRowData(rowData);
    setIsPopupVisible(true);
  };

  const handleExecuteAction = async () => {
    if (!actionSubmitted || !selectedRowData) return;

    try {
      if (!token) throw new Error(`Token not defined.`);
      // Depending on the action, call the appropriate API method
      if (actionSubmitted === 'verify') {
        // Call the verify API method
      } else if (actionSubmitted === 'delete') {
        // Call the delete API method
      }
      // Close the popup and reset the state
      setIsPopupVisible(false);
      setSelectedRowData(null);
      setActionSubmitted('');
    } catch (error) {
      console.error('Error executing action:', error);
    }
  };

  useEffect(() => {
    const fetchBusinessUsers = async () => {
      try {
        if (!token) throw new Error(`Token not defined.`);
        const result = await API.getB2BUsers(token);
        setCustomers(result);
      } catch (err) {
        console.error('Error fetching B2B users:', err);
      }
    };
    fetchBusinessUsers();
  }, [token]);

  useEffect(() => {
    const fetchBusinessCustomers = async () => {
      try {
        if (!token) throw new Error(`Token not defined.`);
        const result = await API.getBuisnessCustomers(token);
        setBusinessUsers(result);
      } catch (err) {
        console.error('Error fetching business customers:', err);
      }
    };
    fetchBusinessCustomers();
  }, [token]);

  const isStringEmail = (email: string): boolean => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const checkIfEmail = isStringEmail('piotr.kalinski@gmail.com');

  console.log({ checkIfEmail });

  const handleFileUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];

      if (file) {
        Papa.parse(file, {
          complete: async (result) => {
            const parsedData = result.data
              .map((row: any) => ({
                email: row[0], // Assuming email is the first column
                client: row[1], // Assuming id is the second column
              }))
              .filter((data) => isStringEmail(data.email));

            console.log({ parsedData });

            try {
              if (!token) throw new Error(`Token not defined.`);
              await API.uploadBatchUsers(token, parsedData);
              alert('Data uploaded successfully!');
            } catch (error) {
              console.error('Error uploading batch data:', error);
              alert('Failed to upload data!');
            }
          },
          header: false, // CSV file does not have a header
        });
      }
    },
    [token], // this is the dependency array for useCallback
  );

  const getCompanyNameFromID = (b2bId: string): string => {
    const foundUser = businessUsers.find((user) => user.id === b2bId);
    return foundUser && foundUser.companyName
      ? foundUser.companyName
      : b2bId === 'exp'
      ? 'expired'
      : 'klient indywidualny';
  };

  const filteredCustomers = useMemo(() => {
    console.log({ selectedCompany });
    if (!selectedCompany || selectedCompany === 'Wszyscy') {
      return customers;
    }

    return customers.filter(
      (customer) => customer?.plan?.b2b === selectedCompany,
    );
  }, [customers, selectedCompany]);

  const handleRowUpdating = async (e: any) => {
    console.log('asd', e.newData.plan.current.expirationDate);
    try {
      if (!token) throw new Error(`Token not defined.`);
      await API.updateUser(
        token,
        e.key,
        e.newData.plan.b2b,
        e.newData.plan.current.expirationDate,
      );
    } catch (err) {
      console.error('Failed to update user:', err);
      e.cancel = true;
    }
  };

  const extendedBusinessUsers = [
    ...businessUsers,
    {
      id: 'b2c',
      companyName: 'klient indywidualny',
    },
  ];

  console.log({
    asdasd111: filteredCustomers,
    asd: filteredCustomers.find(
      (user) => user.email === 'piotr.kalinskvi@stretchme.pl',
    ),
  });

  const onExporting = (e: { [x: string]: any; component: any }) => {
    if (typeof window !== 'undefined') {
      const data = e.selectedRowsOnly
        ? e.component.getSelectedRowsData()
        : e.component.getDataSource().items();
      const jsonString = JSON.stringify(data, null, 2); // Pretty print the JSON

      const blob = new Blob([jsonString], {
        type: 'application/json;charset=utf-8;',
      });

      // Create a link and set the URL to the Blob
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'lista klientow.json');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleRowRemoving = async (e: any) => {
    const { data } = e; // This is the row data that is being removed
    try {
      if (!token) throw new Error(`Token not defined.`);

      await API.deleteUser(token, {
        id: data.id,
        sub: data.attributes.sub,
      });

      // Remove the user from the state to update the DataGrid
      setCustomers(customers.filter((customer) => customer.id !== data.id));
    } catch (err) {
      console.error('Failed to delete user:', err);
      e.cancel = true; // Prevent row removal if there's an error
    }
  };

  return (
    <>
      <Header showButton="menu" theme="light" showConnectButton />
      <Popup
        visible={isPopupVisible}
        onHiding={() => setIsPopupVisible(false)}
        dragEnabled={true}
        closeOnOutsideClick={true}
        showCloseButton={true}
        title="Zarządzaj"
        width={600}
        height="auto"
      >
        <div style={{ padding: '20px' }}>
          {selectedRowData && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                }}
              >
                <Button
                  text="Zweryfikuj"
                  type="default"
                  stylingMode="contained"
                  onClick={() => {
                    setToVerify(!toVerify);
                    console.log({ selectedRowData });
                    if (!token) return null;
                    API.verifyUser(token, {
                      sub: selectedRowData.data.attributes.sub,
                    });
                  }}
                />
                <Button
                  text="Usuń"
                  type="danger"
                  stylingMode="contained"
                  onClick={() => {
                    setToDelete(!toDelete);
                    if (!token) return null;
                    API.deleteUser(token, {
                      sub: selectedRowData.data.attributes.sub,
                      id: selectedRowData.data.id,
                    });
                  }}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  text="Wykonaj"
                  type="success"
                  stylingMode="contained"
                  onClick={handleExecuteAction}
                  disabled={!actionSubmitted}
                />
              </div>
            </>
          )}
        </div>
      </Popup>
      <div
        style={{
          padding: '15px',
          maxWidth: '1950px',
          margin: 'auto',
        }}
      >
        <Text sx={{ textAlign: 'left', marginTop: '120px' }} variant="h3">
          Klienci B2B
        </Text>
        <div style={{ margin: '20px 0' }}>
          <Text>Filtruj użytkowników:</Text>
          <SelectBox
            items={[
              ...businessUsers,
              { id: 'Wszyscy', companyName: 'Wszyscy' },
            ]}
            value={selectedCompany || 'Wszyscy'}
            onValueChanged={(e) => setSelectedCompany(e.value)}
            displayExpr="companyName"
            valueExpr="id"
            placeholder="Select a company"
          />
        </div>
        {/* Upload section */}
        <div style={{ marginTop: '20px' }}>
          <Text>Upload .csv file:</Text>
          <input type="file" accept=".csv" onChange={handleFileUpload} />
        </div>
        {/* End of upload section */}
        <DataGrid
          dataSource={filteredCustomers}
          keyExpr="id"
          onRowUpdating={handleRowUpdating}
          onRowRemoving={handleRowRemoving}
          onExporting={onExporting}
        >
          <Paging enabled={false} />
          <Editing mode="form" allowUpdating={true} allowDeleting={true} />
          <Sorting mode="multiple" />
          <Toolbar>
            <GridButton text="Add Business Code" />
          </Toolbar>
          <SearchPanel visible={true} />
          <Paging enabled={true} />
          <Selection mode="multiple" />
          <Column dataField="email" caption="Email" allowEditing={false} />
          <Column dataField="id" caption="Id" allowEditing={false} />
          <Column
            dataField="plan.current.expirationDate"
            caption="Expiration date"
            dataType="date"
          />
          <Column
            dataField="attributes.custom:code"
            caption="Code"
            allowEditing={false}
          />
          <Column
            dataField="plan.current.purchaseDate"
            caption="Purchase Date"
            dataType="date"
            allowEditing={false}
          />
          <Column
            dataField="plan.b2b"
            caption="Client"
            cellRender={(rowData: any) => {
              console.log({ rowData: rowData.data });
              if (rowData && rowData.data && rowData.data.plan) {
                const companyName = getCompanyNameFromID(rowData.data.plan.b2b);
                return <span>{companyName}</span>;
              }
              return <span>klient indywidualny</span>;
            }}
          >
            <Lookup
              dataSource={extendedBusinessUsers} // Use the modified list with 'B2C' included
              valueExpr="id"
              displayExpr="companyName"
            />
          </Column>
          // column below should have only span witn clickable [Zarządzaj]
          <Column
            dataField="attributes.custom:code"
            caption=""
            allowEditing={false}
            cellRender={(rowData: any) => {
              return (
                <a onClick={() => handleManageClick(rowData)}>Zarządzaj</a>
              );
            }}
          />
          <Export enabled={true} allowExportSelectedData={true} />
        </DataGrid>
      </div>
      <style>{`
        .ReactVirtualized__Table__headerColumn {
          font-size: 14px;
          font-weight: 100;
          font-family: Lato;
          text-transform: capitalize;
          margin-right: 0px;
        }
      `}</style>
    </>
  );
};

export default BuisnessUsersSection;
