/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';
import type { RouteComponentProps } from '@reach/router';
import Header from '../../02_molecules/Header/Header';
import Text from '../../01_atoms/Text/Text';
import Popup from 'devextreme-react/popup';

import DataGrid, {
  Editing,
  Column,
  Selection,
  Button as GridButton,
  Toolbar,
} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button'; // Import Button from DevExtreme

import 'devextreme-react/text-area';
import API from '../../../services/API';
import { BusinessUser, Course, User } from '@youga/youga-interfaces';
import useAuth from '../../../hooks/useAuth';
import 'react-datepicker/dist/react-datepicker.css';
import SelectBox from 'devextreme-react/select-box';

const HomeSection = (_props: RouteComponentProps): React.ReactElement => {
  const { token } = useAuth();
  const [businessUsers, setBusinessUsers] = useState<BusinessUser[]>([]);
  const [courses, setCourses] = useState<Course[]>([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedSession, setSelectedSession] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    console.log('token', token);
    const fetchCourses = async () => {
      try {
        if (token == null) {
          throw new Error(`Token not defined.`);
        }
        const result = await API.getCourses(token);
        setCourses(result);
      } catch (err) {
        console.error('Error fetching business users:', err);
      }
    };
    fetchCourses();
  }, [token]);

  const handleRowInserting = async (e: { data: any }) => {
    const { data } = e;

    if (token == null) {
      throw new Error(`Token not defined.`);
    }

    const newBusinessUser = {
      email: data.email || '',
      periodType: data.periodType || 'normal',
      companyName: data.companyName || 'Unnamed',
      krs: data.krs || '',
      clientType: data.type || 'test',
      numberOfCustomers: data.numberOfCustomers || 0,
    };

    await API.createBuisnessUser(token, newBusinessUser as any);
  };

  const handleVidoeProcessing = async () => {
    try {
      if (token == null) {
        throw new Error(`Token not defined.`);
      }

      const result = await API.processVideo(token);
      console.log('result', result);
    } catch (error) {
      console.error('Failed to process videos:', error);
    }
  };

  const handleAcl = async () => {
    try {
      if (token == null) {
        throw new Error(`Token not defined.`);
      }

      const result = await API.handleAcl(token);
      console.log('result', result);
    } catch (error) {
      console.error('Failed to change acl:', error);
    }
  };

  const handleRowUpdating = async (e: { oldData: any; newData: any }) => {
    try {
      if (token == null) {
        throw new Error(`Token not defined.`);
      }

      const updatedData = {
        id: e.oldData.id || e.newData.id,
        ...e.oldData,
        ...e.newData,
      };

      await API.createBuisnessUser(token, updatedData);

      setBusinessUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === updatedData.id ? updatedData : user,
        ),
      );
    } catch (error) {
      console.error('Failed to update the business user:', error);
    }
  };

  const handleToolbarPreparing = (e: any) => {
    const toolbarItems = e.toolbarOptions.items;

    const addBtn = toolbarItems.find(
      (item: any) => item.name === 'addRowButton',
    );
    if (addBtn) {
      addBtn.options = {
        ...addBtn.options,
        onClick: () => {
          setIsPopupVisible(true);
        },
      };
    }
  };

  return (
    <>
      <Header showButton="menu" theme="light" showConnectButton />
      <Popup
        visible={isPopupVisible}
        onHiding={() => setIsPopupVisible(false)}
        dragEnabled={true}
        closeOnOutsideClick={true}
        showCloseButton={true}
        title="Zarządzaj"
        width={600}
        height="auto"
      >
        <div style={{ padding: '20px' }}>
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '20px',
              }}
            >
              <SelectBox
                dataSource={[
                  'introVideoUrlEng',
                  'headerImage',
                  'posterImage',
                  'thumbnail',
                  'thumbnailHq',
                  'introVideoUrl',
                  'introThumbnail',
                  'introThumbnailHq',
                ]}
                placeholder="Select an option"
                width="100%"
                dropDownOptions={{ width: 500 }}
              />
              <Button
                text="Upload"
                type="default"
                stylingMode="contained"
                onClick={() => {
                  // Upload functionality to be implemented
                }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button text="Wykonaj" type="success" stylingMode="contained" />
            </div>
          </>
        </div>
      </Popup>
      <div
        sx={{
          padding: '15px',
          maxWidth: '9150px',
          margin: 'auto',
        }}
      >
        <Text sx={{ textAlign: 'left', marginTop: '120px' }} variant="h3">
          Kursy
        </Text>
        {/* Adjust button layout to be side by side on the right */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
          }}
        >
          <Button
            text="Video processing"
            style={{ marginRight: '10px' }}
            onClick={handleVidoeProcessing}
          />
          <Button text="ACL" onClick={handleAcl} />
        </div>
        <div id="data-grid-demo">
          <DataGrid
            dataSource={courses}
            keyExpr="id"
            showBorders={true}
            onRowInserting={(e) => handleRowInserting(e)}
            onRowUpdating={handleRowUpdating}
            onToolbarPreparing={handleToolbarPreparing}
          >
            <Toolbar>
              <GridButton text="Add Business Code" />

              <GridButton text="Add Unregistered User" />
            </Toolbar>
            <Selection mode="multiple" />
            <Editing
              mode="form"
              allowUpdating={true}
              allowAdding={true}
              allowDeleting={true}
            />
            <Column dataField="title" caption="Title" />
            <Column dataField="subtitle" caption="Subtitle" visible={false} />
            <Column
              dataField="description"
              caption="Description"
              visible={false}
            />
            <Column
              dataField="instructor"
              caption="Instructor"
              visible={false}
            />
            <Column
              cellRender={() => (
                <a onClick={() => setIsPopupVisible(true)}>Upload content</a>
              )}
              caption="Action"
            />
            <Column
              dataField="introVideoDuration"
              caption="Intro Video Duration"
            />
            <Column dataField="props" caption="Props" />
            <Column
              dataField="introTitle"
              caption="Intro Title"
              visible={false}
            />

            <Column dataField="Notes" visible={false}></Column>
          </DataGrid>
        </div>
      </div>

      <style>{`
        .ReactVirtualized__Table__headerColumn {
          font-size: 14px;
          font-weight: 100;
          font-family: Lato;
          text-transform: capitalize;
          margin-right: 0px;
        }
      `}</style>
    </>
  );
};

export default HomeSection;
