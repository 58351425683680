/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';
import type { RouteComponentProps } from '@reach/router';
import Header from '../../02_molecules/Header/Header';
import Text from '../../01_atoms/Text/Text';
import DataGrid, {
  Editing,
  Column,
  Selection,
  Export,
} from 'devextreme-react/data-grid';

import 'devextreme-react/text-area';
import API from '../../../services/API';
import { BusinessUser, User } from '@youga/youga-interfaces';
import useAuth from '../../../hooks/useAuth';
import jsPDF from 'jspdf';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import 'react-datepicker/dist/react-datepicker.css';

const HomeSection = (_props: RouteComponentProps): React.ReactElement => {
  const { token } = useAuth();
  const [businessUsers, setBusinessUsers] = useState<BusinessUser[]>([]);
  const [customers, setCustomers] = useState<User[]>([]);

  useEffect(() => {
    const fetchBusinessUsers = async () => {
      try {
        if (token == null) {
          throw new Error(`Token not defined.`);
        }
        const result = await API.getBuisnessCustomers(token);
        setBusinessUsers(result);
      } catch (err) {
        console.error('Error fetching business users:', err);
      }
    };
    fetchBusinessUsers();
  }, [token]);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        if (!token) throw new Error(`Token not defined.`);
        const result = await API.getB2BUsers(token);
        setCustomers(result);
      } catch (err) {
        console.error('Error fetching customers:', err);
      }
    };
    fetchCustomers();
  }, [token]);

  const handleRowRemoving = async (e: { key: any }) => {
    const { key } = e;
    try {
      if (token == null) {
        throw new Error(`Token not defined.`);
      }
      await API.deleteBuisnessUser(token, key);
    } catch (error) {
      console.error('Failed to delete the business user:', error);
    }
  };

  const handleRowInserting = async (e: { data: any }) => {
    const { data } = e;

    if (token == null) {
      throw new Error(`Token not defined.`);
    }

    const newBusinessUser = {
      email: data.email || '',
      periodType: data.periodType || 'normal',
      companyName: data.companyName || 'Unnamed',
      krs: data.krs || '',
      clientType: data.type || 'test',
      numberOfCustomers: data.numberOfCustomers || 0,
    };

    await API.createBuisnessUser(token, newBusinessUser as any);
  };

  const handleRowUpdating = async (e: { oldData: any; newData: any }) => {
    try {
      if (token == null) {
        throw new Error(`Token not defined.`);
      }

      const updatedData = {
        id: e.oldData.id || e.newData.id,
        ...e.oldData,
        ...e.newData,
      };

      await API.createBuisnessUser(token, updatedData);

      setBusinessUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === updatedData.id ? updatedData : user,
        ),
      );
    } catch (error) {
      console.error('Failed to update the business user:', error);
    }
  };

  const onExporting = (e: { component: any }) => {
    console.log({ e });
    const doc = new jsPDF();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('Klienci Stretchme.pdf');
    });
  };

  return (
    <>
      <Header showButton="menu" theme="light" showConnectButton />
      <div
        sx={{
          padding: '15px',
          maxWidth: '9150px',
          margin: 'auto',
        }}
      >
        <Text sx={{ textAlign: 'left', marginTop: '120px' }} variant="h3">
          Klienci Biznesowi
        </Text>
        <div id="data-grid-demo">
          <DataGrid
            dataSource={businessUsers}
            keyExpr="id"
            showBorders={true}
            onRowInserting={handleRowInserting}
            onRowRemoving={handleRowRemoving}
            onExporting={onExporting}
            onRowUpdating={handleRowUpdating}
          >
            <Selection mode="multiple" />
            <Editing
              mode="form"
              allowUpdating={true}
              allowAdding={true}
              allowDeleting={true}
            />
            <Column dataField="companyName" caption="Nazwa firmy" />
            <Column dataField="email" caption="E-mail" />
            <Column dataField="clientType" caption="Typ" />
            <Column
              dataField="createdAt"
              caption="Data dodatania"
              dataType="date"
              allowEditing={false}
            />
            <Column
              dataField="cancellationDate"
              caption="Data zakończenia"
              width={170}
            />
            <Column
              dataField="numberOfCustomers"
              caption="Liczba uzytkowników"
              allowEditing={false}
              calculateCellValue={(rowData: any) => {
                return customers.filter((data) => data.plan?.b2b === rowData.id)
                  .length;
              }}
            />

            <Column
              dataField="updatedAt"
              dataType="date"
              caption="Ostatnia edycja"
              allowEditing={false}
            />
            <Column dataField="Notes" visible={false}></Column>

            <Export enabled={true} allowExportSelectedData={true} />
          </DataGrid>
        </div>
      </div>

      <style>{`
        .ReactVirtualized__Table__headerColumn {
          font-size: 14px;
          font-weight: 100;
          font-family: Lato;
          text-transform: capitalize;
          margin-right: 0px;
        }
      `}</style>
    </>
  );
};

export default HomeSection;
